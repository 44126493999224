html,body{
    height: 100%;
    margin: 0;
    font-family: "DM Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

li.nav-item {
    font-family: "DM Sans", sans-serif;
    height:42px;
}


li.nav-item.active > a.nav-link {
    white-space: nowrap;
    font-weight: bold;
}

li.nav-item > a.nav-link {
    white-space: nowrap;
}

li.nav-item > a.nav-link:hover {
   background-color: #669FCC;
}

button.btn_menu:hover {
    background-color: #669FCC;
}



p,.label {
    font-family: arial, helvetica, sans-serif;
}

/* If not, cause horizontal scroll inside VO Card registration */
.row {
    margin-right:0px;
}

#navbarMain {
    padding: 8px 16px;
}

.navbar-brand {
    display: inline-block;
}

.navbar-text {
    padding: 0 10px;
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;

}

.navbar .fa-user {
    color:#FFF;
}


//#background-image {
//    min-height: 100%;
//    position: relative;
//    overflow: hidden;
//    float: left;
//
//    &::before {
//        background-image: url('../images/background.jpg');
//        background-repeat: no-repeat;
//        background-position: center top;
//        background-size: cover;
//        content: '';
//        height: 100%;
//        left: 0;
//        position: fixed;
//        top: 0;
//        width: 100%;
//        will-change: transform;
//        z-index: -1;
//        opacity: .5;
//
//    }
//}

.container {
    min-height:100%;
}

#module-container {
    height:370px;
}

#broadcast-container {
    overflow-y:auto;
    height:660px;
}


label.required:after  {
    content: " *";
    color: #cd0a0a;
}

.form-group {
    margin-bottom: 10px; }

#dropdown-menu {
    display:inline;
}

.bold {
    font-weight: bold!important;
}

.italic {
    font-style: italic;
}

.navbar-nav>.active {
    background-color: #1E325C !important;
    font-weight: bold;
}

.page-header, .page-body {
    background-color: #FFF;
}

table.table-bordered.dataTable {
    outline: 1px #F7F6F7 solid!important;
}

.dataTables_wrapper .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;

}

.accordion-header-custom a {
    text-decoration: none;
}

.accordion-header-custom .card-title {
    font-weight: normal;
}

th.search {
    font-size: 12px;
}

th.search > input {
    padding-right: 0px;
}

.hide {
    display: none;
}

label {
    margin-bottom: .5rem;
}

.pointer {
    cursor: pointer;
}

#aup_file_block .row > * {
    width: auto;
}

#VOD_section #disciplines_q {
    display: inline;
    width: auto;
}

form label.error, label.error {
    color: #cd0a0a;
}

.dropdown-menu>li>a {
    color: #262626;
    margin: 0 20px;
}

select.form-control:not(.form-select,.custom-select) {
    -webkit-appearance: menulist; // Otherwise, the dropdown list arrow doesn't shows up
}

/*----------------------------------*/
/* loaders */

.loader-wrapper.absolute {
    width: 100%;
    min-height: 1000%;
    position: absolute;
    z-index: 1070;
    top: 0;
    left: 0;
    background-color: #F7F6F7;
    height: 100%;
    opacity: 0.8;
}

.loader-wrapper.absolute .loader {
    top: 5%;

}


.loader-wrapper .loader {
    margin-right: auto;
    margin-left: auto;
    top: 40%;
    position: relative;
    height: 70px;
    width: 70px;
    float : inherit;
}

.loader, .loader-middle {
    border-bottom: 4px solid #ddd;
    border-left: 4px solid #ddd;
    border-right: 4px solid #003bb3;
    border-top: 4px solid #003bb3;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    float: left;
    -webkit-animation: spin .6s infinite linear;
    -moz-animation: spin .6s infinite linear;
    -ms-animation: spin .6s infinite linear;
    -o-animation: spin .6s infinite linear;
    animation: spin .6s infinite linear;
    margin-left : 45%

}

/* --------------- Global styles --------------- */



.card-body h2.portal {
    color: #1E325C;
    padding-bottom: 4px;
    border-bottom-color: rgba(0, 85, 128, 0.16);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    margin-bottom: 20px;
}

.dataTable tr.selected td {
    background-color: #B0BED9 !important; /* Add !important to make sure override datables base styles */
}


li.list-style-none {
    list-style-type: none;
}


/* -------   font size classes   ------- */

.fs-12px {
  font-size: 12px;
}

.fs-14px {
  font-size: 14px;
}

.fs-15px {
  font-size: 15px;
}

.fs-16px {
  font-size: 16px;
}

.fs-18px {
  font-size: 18px;
}

.fs-20px {
    font-size: 20px;
}

.fs-24px {
    font-size: 24px;
}

.fs-25px {
  font-size: 25px;
}

.fs-30px {
    font-size: 30px;
}

.fs-40px {
    font-size: 40px;
}




.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center; /* Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height) */

    .form-control {
        display: inline-block;
        width: auto; /* Prevent labels from stacking above inputs in `.form-group` */
        vertical-align: middle;
    }
}
