$info: #669FCC;

$primary: #005FAA;
$secondary: #1E325C;
$text-dark:#1E325C;
$danger:#FF000E;
$success:#63C247;
$warning:#FFC000;
$light:#F7F6F7;
// Default variable overrides
$body-bg: rgb(255, 255, 255);

@mixin gradient-x($start-color: $gray-700, $end-color: $gray-800, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
}
