.btn-danger {
    font-weight: bold;
}

.btn-primary {
    font-weight: bold;
}

.btn-success{
    font-weight: bold;
}


.bg-success {
    color:white;
}


